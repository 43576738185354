/* Estilo específico para a página de conectores */
.conectores-page {
    background-color: #1c1c1c; /* Cor de fundo específica para esta página */
    min-height: 100vh; /* Garantir que o fundo cubra a tela inteira */
    color: #fff;
}

/* Resto do código */
.conectores-container {
    text-align: center;
    padding: 20px;
    color: #fff; /* Texto branco */
}
.h1co {
    
    color: #fff; /* Texto branco */
}

.search-bar {
    width: 400px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.conectores-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 32%;
}

.conector-card {
    background-color: #2b2b2b;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    width: 200px;
    text-align: left;
    padding: 15px;
    position: relative;
}

.conector-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.conector-icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    padding: 10px;
}

.conector-body h3 {
    margin: 0;
    font-size: 18px;
}

.conector-body p {
    color: #b0b0b0;
    font-size: 14px;
    margin: 5px 0;
}

.gerenciar-btn {
    background-color: #016440;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.gerenciar-btn:hover {
    background-color: #0056b3;
}
