.dashboard-container {
  padding: 20px;
  background-color: transparent;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  margin-left: 15%;
}

.dashboard-container h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Container dos cartões */
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px; /* Espaçamento entre os cartões */
  padding: 20px; /* Espaçamento interno no container */
}

.card {
  background-color: #1c1c1e;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
}

/* Estilo dos cartões de nível */
.level-card h2 {
  font-size: 48px;
  margin-bottom: 10px;
}

.level-card h3 {
  font-size: 20px;
  color: #b4b6c0;
}

/* Barra de progresso */
.progress-container {
  margin-top: 20px;
}

.progress-item {
  margin-bottom: 10px;
}

.progress-bar {
  width: 100%;
  background-color: #2c2f3a;
  border-radius: 5px;
  height: 8px;
  margin-top: 5px;
}

.progress-fill {
  background-color: #4caf50;
  height: 100%;
  border-radius: 5px;
}

/* Cartões específicos */
.sales-card, .revenue-card, .small-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sales-card h2, .revenue-card h2 {
  font-size: 20px;
  color: #b4b6c0;
}

.sales-card h3, .revenue-card h3 {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.small-card h2 {
  font-size: 16px;
  color: #b4b6c0;
}

/* Responsividade */
@media (max-width: 768px) {
  .dashboard-container {
    margin-left: 0; /* Remove o espaçamento para dispositivos móveis */
    padding: 15px;
  }

  .dashboard-container h1 {
    font-size: 20px;
    text-align: center;
  }

  .cards-container {
    gap: 15px;
    padding: 15px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Ajusta o tamanho mínimo dos cartões */
  }

  .card {
    padding: 15px;
  }

  .level-card h2 {
    font-size: 36px;
  }

  .level-card h3 {
    font-size: 18px;
  }

  .sales-card h2, .revenue-card h2 {
    font-size: 18px;
  }

  .sales-card h3, .revenue-card h3 {
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .dashboard-container h1 {
    font-size: 18px;
  }

  .cards-container {
    gap: 10px;
    padding: 10px;
    grid-template-columns: 1fr; /* Um cartão por linha */
  }

  .card {
    padding: 10px;
  }

  .level-card h2 {
    font-size: 32px;
  }

  .level-card h3 {
    font-size: 16px;
  }

  .sales-card h2, .revenue-card h2 {
    font-size: 16px;
  }

  .sales-card h3, .revenue-card h3 {
    font-size: 20px;
  }
}
