.combined-form-container .combined-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    color: #013220;
  }
  
  .combined-form-container .combined-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .combined-form-container .input-group {
    margin-bottom: 15px;
  }
  
  .combined-form-container .input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .combined-form-container .input-group input,
  .combined-form-container .input-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0;
  }
  
  .combined-form-container button {
    width: 100%;
    padding: 10px;
    background-color: #013220;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .combined-form-container button:disabled {
    background-color: #ccc;
  }
  
  .combined-form-container .error-message {
    color: red;
    text-align: center;
  }
  .logopa {
    display: block;
    margin: 0 auto 20px; /* Centraliza o logo e adiciona uma margem inferior */
    max-width: 100px; /* Define uma largura máxima para o logo */
    height: auto; /* Mantém a proporção do logo */
}
