/* Estilos gerais da página de configurações */
.settings-container {
    background-color: #1c1c1c;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    min-height: 100vh;
    min-width: 100vh;
    transition: margin-left 0.3s ease;
}

h1 {
    text-align: center;
    color: white;
    margin-bottom: 20px;
    font-size: 24px;
}

.settings-list {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Adiciona espaçamento consistente */
    margin-left: 40vh;
    margin-right: 40vh;
    color: white; /* Texto branco por padrão */
}

.setting-item {
    background-color: #838282;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, color 0.3s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    color: white; /* Texto branco por padrão */
}

.setting-item:hover {
    background-color: #f0f0f0;
    transform: translateY(-2px); /* Efeito suave ao passar o mouse */
    color: black; /* Texto preto no hover */
}

.setting-title {
    font-size: 20px;
    font-weight: 600;
}

.sub-settings-list {
    margin-top: 10px;
    padding-left: 20px;
}

/* Submenu Item */
.sub-setting-item {
    margin-bottom: 8px;
    padding: 8px;
    background-color: #2b2b2b; /* Cor de fundo mais escura */
    border-left: 4px solid #4a90e2; /* Borda azul para destacar */
    color: #dcdcdc; /* Texto em cinza claro */
    cursor: pointer;
    transition: background-color 0.3s, padding-left 0.2s, color 0.3s;
}

/* Hover no Submenu */
.sub-setting-item:hover {
    background-color: #3a3a3a; /* Cor de hover mais clara */
    padding-left: 25px; /* Suave deslocamento ao passar o mouse */
    color: #ffffff; /* Texto em branco no hover */
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
    .settings-container {
        margin-left: 5%; /* Reduz a margem em telas menores */
        margin-right: 5%; /* Reduz a margem em telas menores */
        padding: 15px; /* Ajusta o padding */
    }

    h1 {
        font-size: 20px; /* Reduz o tamanho da fonte do título */
    }

    .setting-title {
        font-size: 18px; /* Reduz o tamanho da fonte para os títulos das configurações */
    }
}

@media (max-width: 480px) {
    .settings-container {
        margin-left: 2%; /* Ajusta a margem em telas muito pequenas */
        margin-right: 2%; /* Ajusta a margem em telas muito pequenas */
        padding: 10px; /* Ajusta o padding para telas muito pequenas */
    }

    h1 {
        font-size: 18px; /* Reduz ainda mais o tamanho da fonte do título */
    }

    .setting-title {
        font-size: 16px; /* Reduz ainda mais o tamanho da fonte para os títulos das configurações */
    }

    .setting-item {
        padding: 10px; /* Reduz o padding dos itens das configurações */
    }

    .sub-setting-item {
        padding: 6px; /* Ajusta o padding dos sub-itens */
    }
}

.expanded .settings-container {
    margin-left: 250px; /* Largura expandida da sidebar */
}

.collapsed .settings-container {
    margin-left: 80px; /* Largura retraída da sidebar */
}