.reset-password-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
    color: #013220;
  }
  
  .reset-password-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .reset-password-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .reset-password-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .reset-password-container button {
    width: 100%;
    padding: 12px;
    background-color: #013220;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .reset-password-container button:hover {
    background-color: #303f9f;
  }
  
/* Estilo básico da modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro com opacidade */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Certifique-se de que a modal está acima de outros elementos */
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.modal h3 {
  margin-bottom: 10px;
}

.modal p {
  margin-bottom: 20px;
}

.modal button {
  padding: 10px 20px;
  background-color: #013220; /* mesma cor dos outros botões */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #303f9f;
}