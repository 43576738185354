.priconfig-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
  }
  
  .priconfig-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .priconfig-container label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .priconfig-container select,
  .priconfig-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .priconfig-container button {
    width: 100%;
    padding: 12px;
    background-color: #013220;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .priconfig-container button:hover {
    background-color: #303f9f;
  }
  
  .message {
    text-align: center;
    color: green;
    margin-top: 15px;
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
  }
  
  .modal-content h2 {
    margin-bottom: 10px;
  }
  
  .modal-content p {
    margin-bottom: 20px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background: #0056b3;
  }

  