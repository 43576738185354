/* SignupForm.css */

.form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-container input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-container button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50; /* Verde */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form-container button:hover {
    background-color: #45a049; /* Verde escuro */
  }
  
  .form-container p {
    color: red;
    text-align: center;
  }
  