body {
  background-color: #121212; /* Cor de fundo escura */
  color: #fff; /* Cor do texto clara para contraste */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Lista de produtos - ajustando a margem para a sidebar */
.productList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsividade */
  gap: 20px;
  padding: 20px;
  margin-left: 15%; /* Margem inicial para sidebar expandida */
  background-color: #121212;
  transition: margin-left 0.3s ease; /* Adiciona transição suave na mudança de margem */
}

/* Quando a sidebar está expandida */
.expanded .productList {
  margin-left: 250px; /* Ajuste para sidebar expandida */
}

/* Quando a sidebar está recolhida */
.collapsed ~ .productList {
  margin-left: 80px; /* Ajusta para a sidebar recolhida */
}

/* Cartões de produtos */
.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.2s ease-in-out;
}

.image {
  max-width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}

.card h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.card p {
  margin: 10px 0;
  font-size: 14px;
  color: #666;
}

.card:hover {
  transform: scale(1.05);
}

/* Estilos para o select */
select {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto; /* Centraliza o select */
}

/* Barra de pesquisa abaixo do select */
.searchBar {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #222;
  color: #fff;
  margin-left: 40%;
}

.searchBar::placeholder {
  color: #aaa;
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
  .productList {
    margin-left: 80px; /* Ajuste a margem para sidebar recolhida */
    margin-left: -20%;
  }

  .sidebar.collapsed ~ .productList {
    margin-left: 50px;
  }

  select,
  .searchBar {
    max-width: 100%; /* Ajusta largura do select e barra de pesquisa para caber na tela */
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .productList {
    grid-template-columns: 1fr; /* Exibe uma coluna em dispositivos móveis */
    margin-left: 0; /* Remove margem para sidebar em telas pequenas */
    padding: 10px; /* Reduz padding para telas pequenas */
    margin-left: -20%;
  }

  .card h2 {
    font-size: 16px; /* Ajusta tamanho da fonte para telas pequenas */
  }

  .card p {
    font-size: 12px; /* Ajusta tamanho da fonte para o texto */
  }

  .searchBar {
    width: 90%; /* Ajusta barra de pesquisa para caber melhor em telas pequenas */
    margin: 10px auto;
  }

  select {
    width: 90%; /* Ajusta largura do select para caber melhor */
    margin: 10px auto;
  }
}
