/* Estilos gerais da página de detalhes do conector */
.conector-detalhe-page {
    padding: 20px;
    background-color: #121212; /* Fundo escuro para combinar com o estilo do tema */
    color: #e0e0e0; /* Texto claro para contraste */
    min-height: 100vh;
}

.voltar-btn {
    background-color: #1f1f1f;
    color: #e0e0e0;
    border: none;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.voltar-btn:hover {
    background-color: #333;
}

.h1co {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Alinha os itens um embaixo do outro */
.conector-detalhe-container {
    display: flex;
    flex-direction: column; /* Muda para coluna */
    justify-content: flex-start;
    align-items: center; /* Centraliza os itens horizontalmente */
    gap: 20px;
}

/* Estilos da área de informações do conector */
.conector-info {
    width: 100%; /* Largura completa para a coluna */
    padding: 20px;
    background-color: #1f1f1f;
    border-radius: 10px;
    text-align: center;
}

.conector-icon-detalhe {
    width: 100px;
    height: auto;
    margin-bottom: 20px;
}

.conector-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.conector-info p {
    font-size: 16px;
    color: #b0b0b0;
}

/* Estilos da área de configurações */
.conector-config {
    width: 100%; /* Largura completa para a coluna */
    padding: 20px;
    background-color: #1f1f1f;
    border-radius: 10px;
    margin-left: 50vh;
    margin-right: 50vh;
}

.conector-config h3 {
    font-size: 22px;
    margin-bottom: 20px;
    margin-left: 85vh;
}

.input-config {
    margin-left: 85vh;
    
    width: 10%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #333;
    border: 1px solid #555;
    border-radius: 5px;
    color: #e0e0e0;
}

.input-config:focus {
    outline: none;
    border-color: #777;
}

/* Estilos para o status da integração */
.status-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 85vh;
}

.status-container p {
    margin-right: 10px;
    font-size: 16px;
    
    
}
.p2{
    margin-left: 80vh;
}
.status-conectado {
    background-color: #00c853;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}

/* Estilos do botão salvar */
.salvar-btn {
    background-color: #6200ea;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-left: 80vh;
}

.salvar-btn:hover {
    background-color: #3700b3;
}


/* Botão de conectar */
.conectar-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 86vh;
}

.conectar-btn:hover {
    background-color: #45a049;
}

/* Botão de desconectar */
.desconectar-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 86vh;

}

.desconectar-btn:hover {
    background-color: #e53935;
}