/* Botão de Hambúrguer */
.hamburgerButton {
    display: none;
    position: fixed;
    top: 15px;
    left: 15px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    z-index: 1100;
    cursor: pointer;
}

/* Sidebar */
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #1a1a1a;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, width 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(0);
}

.collapsed {
    width: 80px;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 768px) {
    .hamburgerButton {
        display: block;
    }

    .sidebar {
        transform: translateX(-100%);
        width: 80px;
    }

    .mobileOpen {
        transform: translateX(0);
        width: 250px;
    }

    .expanded {
        width: 250px;
    }

    .collapsed {
        width: 80px;
    }

    .sidebarHeader {
        display: none !important;
    }
    
    .menu {
        margin-top: 60%;
    }
}

/* Ajuste adicional para telas muito pequenas (ex.: celulares) */
@media (max-width: 480px) {
    .sidebar {
        width: 100px;
    }

    .content {
        margin-left: 100px;
        padding: 10px;
    }

    .collapsed ~ .content {
        margin-left: 30px;
    }

    .sidebarHeader {
        flex-direction: column;
    }

    .expandButton {
        position: static;
        margin-top: 10px;
    }

    .sidebarHeader {
        display: none !important;
    }

    .menu {
        margin-top: 60%;
    }
    
}

/* Container */
.container {
    display: flex;
}

/* Header */
.sidebarHeader {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    transition: opacity 0.3s ease;
}

.sidebarHeader h2 {
    transition: opacity 0.3s ease;
}

.collapsed .sidebarHeader h2 {
    opacity: 0;
}

/* Botão de Expansão */
.expandButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: transform 0.3s ease;
}

/* Menu e Footer */
.menu ul, .menuFooter ul {
    list-style-type: none;
    padding: 0;
}

.menu li, .menuFooter li {
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

.menu li:hover, .menuFooter li:hover {
    background-color: #16640f;
}

.menu li span, .menuFooter li span {
    margin-left: 10px;
    display: inline-block;
}

.collapsed .menu li span, .collapsed .menuFooter li span {
    display: none;
}

.menuFooter {
    padding: 1rem;
    border-top: 1px solid #333;
}

/* Links */
.link {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.link:hover {
    color: #f0f0f0;
}

/* Conteúdo da Página */
.content {
    padding: 20px;
    transition: margin-left 0.3s ease;
    margin-left: 250px;
}

.collapsed ~ .content {
    margin-left: 80px;
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
    .sidebar {
        width: 200px;
    }

    .content {
        margin-left: 200px;
    }

    .collapsed ~ .content {
        margin-left: 50px;
    }

    .sidebarHeader {
        display: none !important;
    }

    .menu {
        margin-top: 60%;
    }
    
}
