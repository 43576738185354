/* Estilos Gerais */
@import '@fortawesome/fontawesome-free/css/all.min.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #fefefe;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: #013220;
}

/* Cabeçalho */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: transparent;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 70px;
  transition: background-color 0.3s, color 0.3s;
}

.header.scrolled {
  background-color: #fff;
  color: #000;
}

.logo {
  font-size: 1.5rem;
  /* Reduz um pouco o tamanho do texto */
  font-weight: 600;
  /* Peso da fonte para um look mais moderno */
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  /* Adiciona um leve espaçamento entre letras */
  color: #fff;
  /* Mantém a cor branca no início */
  transition: color 0.3s ease;
  /* Transição suave ao rolar a página */
}

.logo::before {
  content: '';
  /* O ícone */
 
 
  background-image: url('./logos/logom.png');
  background-size: contain; /* Ajusta o tamanho da imagem */
  background-repeat: no-repeat; /* Não repete a imagem */
  width: 12rem; /* Largura do ícone */
  height: 7rem; /* Altura do ícone */
  margin-right: -6.5rem;
  
}

.header.scrolled .logo {
  color: #013220;
  /* Altera a cor para verde-escuro ao rolar */
}

.header.scrolled .logo::before {
  background-image: url('./logos/logom2.png');
  
  /* Altera a cor para verde-escuro ao rolar */
}

.logo span {
  color: inherit;
  /* Mantém a cor dinâmica do logo */
  font-weight: 700;
  /* Adiciona mais ênfase ao nome */
  font-size: 1.5rem;
  /* Ajusta o tamanho da fonte para ser proporcional ao ícone */
  transition: all 0.3s ease;
}

.logo span:hover {
  color: #007BFF;
  /* Altera a cor ao passar o mouse para um tom moderno */
  text-decoration: underline;
  /* Adiciona um sublinhado suave no hover */
}

.hamburger {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.nav {
  display: flex;
  align-items: center;
}

.nav span {
  margin-left: 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 20px;
  color: inherit;
  transition: color 0.3s;
}

.nav span:hover,
.header.scrolled .nav span:hover {
  color: #013220;
}

/* Hero Section */
.hero {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  color: white;
  background-color: none;
}

.videoBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  /* Aumente a altura do vídeo */
  object-fit: cover;
  z-index: 1;
  /* Mantém o vídeo no fundo */
  opacity: 0.8;
}

.videoCredits {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 10px;
  color: black;

  padding: 2px 5px;
  border-radius: 3px;
}

.videoCredits a {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1vh;
  color: white;
  /* Define a cor preta para o link */
  text-decoration: none;
  /* Remove o sublinhado */
  padding: 2px 5px;
  border-radius: 3px;
}

.videoCredits a:hover {
  color: #333;
  /* Define a cor do link ao passar o mouse (opcional) */
}

.heroText {
  position: relative;
  max-width: 45%;
  z-index: 1;
  margin-left: 5vh;
}

.heroTitle {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.heroSubtitle {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.heroButtons {
  margin-top: 20px;
}

.primaryButton,
.secondaryButton {
  padding: 15px 30px;
  font-size: 1rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  transition: background-color 0.3s;
}

.primaryButton {
  background-color: #013220;
  color: white;
}

.secondaryButton {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

.primaryButton:hover {
  background-color: #028a0f;
}

.secondaryButton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.heroImage {
  display: flex;
  justify-content: flex-end;
}

.imageContainer {
  position: relative;
  width: 100%;
}

.heroMainImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  /* Ajusta a imagem */
}

.badge {
  position: absolute;
  top: 15%;
  left: 5%;
  background-color: #007BFF;
  color: white;
  padding: 8px 15px;
  border-radius: 50px;
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
}

/* Seções de Funcionalidades e Depoimentos */
.features,
.testimonials,
.parceiros,
.categorias,
.planos {
  padding: 80px 20px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.features {
  padding: 80px 20px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* overflow: hidden; */
  z-index: 2;
  background-color: #f4f1eb;
}

.planos {
  padding: 80px 20px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* overflow: hidden; */
  z-index: 2;
  background-color: #f4f1eb;
}

.sectionTitle {
  font-size: 2rem;

  margin-bottom: 40px;
  font-weight: bold;

  margin-bottom: 40px;
}

.featuresContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.featureItem {
  flex-basis: 30%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: left;
}

.featureItem h4 {
  margin-top: 5%;
  font-size: 150%;
  color: #013220;

}

.featureItem p {
  margin-top: 5%;
  font-size: 120%;
  color: #013220;

}

.featureIcon {
  margin-bottom: 15px;
}

.iconImage {
  width: 60px;
  height: 60px;
}

.description {
  font-size: 1rem;
}

.testimonials {
  background-color: #013220;
  color: white;
}

.timeline-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  position: relative;

}


.timeline {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 5px;
  background-color: #ccc;
  transform: translateX(-50%);
  z-index: 0;
  /* A linha da timeline deve ficar atrás dos outros elementos */
  max-height: 96%;
}



.flow-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 50px 0;
  position: relative;
  z-index: 1;
  /* Itens devem aparecer acima da timeline */
}


.flow-item {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.flow-item h3 {
  margin-top: 15%;
  font-size: 300%;
}

.flow-item p {
  font-size: 150%;
  margin-top: 10%;
}

.flow-item img {
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  margin-left: -20%;
}

/* Estilo dos números */
.flow-item.left::before,
.flow-item.right::before {
  content: attr(data-number);
  position: absolute;
  font-size: 36px;
  font-weight: bold;
  color: #e0e0e0;
}

.flow-item.left::before {
  left: -50px;
}

.flow-item.right::before {
  right: -50px;
}

/* Botão */
.start-button {
  padding: 15px 30px;
  background-color: #013220;
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 50px;
  position: relative;
  z-index: 2;
  transition: background-color 0.3s;
  /* Certifica-se que o botão esteja sobre a timeline */
}

.start-button:hover {
  background-color: #028a0f;
}

.categorias {
  background-color: #f4f1eb;
  color: #013220;;
}

.parceiros {
  background-color: #013220;
  color: white;
}

/* Footer */
.footer {
  padding: 40px 0;
  text-align: center;
  background-color: #333;
  color: #fff;
}

.footer p {
  font-size: 0.9rem;
}

/* Redes Sociais */
.social-icons a {
  color: white;
  /* Cor padrão */
  font-size: 1.2rem;
  transition: color 0.3s ease, transform 0.3s ease;
  /* Transição ajustada */
  display: flex;
  align-items: center;
}

.social-icons a i {
  margin-right: 5px;
}

/* Altera a cor dos ícones ao passar o mouse */
.social-icons a:hover {
  color: #013220;
  /* Cor ao passar o mouse */
  transform: scale(1.1);
  /* Pequeno efeito de escala para destacar o ícone */
}

.header.scrolled .social-icons a {
  color: #000000;
  transition: color 0.3s ease, transform 0.3s ease;
  /* Transição ajustada */
}

/* Adiciona hover para o estado scrolled */
.header.scrolled .social-icons a:hover {
  color: #013220;
  /* Cor dourada ao passar o mouse */
  transform: scale(1.1);
  /* Mesmo efeito de escala para consistência */
}

/* Responsividade */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #fff;
  }

  .nav.active {
    display: flex;
  }

  .nav span {
    margin: 10px 0;
    text-align: center;
  }

  .hamburger {
    display: block;
  }

  .hero {
    flex-direction: column;
    text-align: center;
  }

  .heroText {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .heroImage {
    justify-content: center;
  }

  .badge {
    left: 5%;
  }
}

@media (max-width: 480px) {
  .hero {
    padding: 60px 10px;
  }

  .heroTitle {
    font-size: 2rem;
  }

  .heroSubtitle {
    font-size: 1rem;
  }
}

.nav span:last-child {
  margin-right: 20px;
}

.parceiros {
  text-align: center;
  /* background-color: #0a1a45; Cor de fundo semelhante à imagem */
  padding: 40px 20px;
}

.sectionTitle {
  font-size: 1.8rem;

  margin-bottom: 20px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.logo2 {
  width: 80px;
  /* Tamanho de cada logotipo */
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  padding: 10px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.1);
  /* Efeito ao passar o mouse */
}

.connectButton {
  background-color: #4a6cf7;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
}

.connectButton:hover {
  background-color: #028a0f;
}


/* Responsividade para telas médias (tablets e pequenos desktops) */
@media (max-width: 1024px) {
  .featuresContainer {
    flex-direction: column;
    /* Alinha os itens da feature verticalmente */
    gap: 20px;
  }

  .header {
    padding: 10px 20px;
  }

  .heroText {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .heroTitle {
    font-size: 2rem;
  }

  .heroSubtitle {
    font-size: 1.1rem;
  }

  .primaryButton,
  .secondaryButton {
    font-size: 0.9rem;
    padding: 12px 24px;
  }

  .flow-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .flow-item {
    width: 100%;
    max-width: 600px;
    margin-bottom: 30px;
  }

  .timeline {
    display: none;
    /* Remove a linha de timeline em dispositivos móveis */
  }
}

/* Responsividade para telas pequenas (smartphones) */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    /* Organização mais compacta do cabeçalho */
    padding: 10px 15px;
    height: auto;
  }

  .hamburger {
    display: block;
    /* Mostra o menu hambúrguer para telas pequenas */
  }

  .nav {
    flex-direction: column;
    background-color: #fff;
    /* Cor de fundo do menu para contraste */
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    display: none;
    z-index: 1000;
  }

  .nav.active {
    display: flex;
  }

  .hero {
    flex-direction: column;
    text-align: center;
  }

  .heroTitle {
    font-size: 1.8rem;
    margin-top: 40vh;
  }

  .heroSubtitle {
    font-size: 1rem;
  }

  .featuresContainer {
    flex-direction: column;
  }

  .features,
  .testimonials,
  .parceiros,
  .categorias {
    padding: 40px 10px;
  }

  .featureItem {
    flex-basis: 100%;
    /* Deixa os itens de feature em 100% da largura */
    margin-bottom: 20px;
  }

  .flow-item img {
    margin-left: 0;
    width: 80%;
  }

  .social-icons {
    justify-content: center;
    /* Centraliza ícones sociais */
  }
}

@media (max-width: 480px) {
  .heroTitle {
    font-size: 1.6rem;
  }

  .heroSubtitle {
    font-size: 0.9rem;
  }

  .badge {
    font-size: 0.8rem;
    /* Reduz o tamanho do badge */
  }

  .social-icons a {
    font-size: 1rem;
    /* Diminui os ícones de redes sociais */
  }

  .featuresContainer {
    gap: 10px;
    /* Reduz o espaçamento para telas menores */
  }

  .testimonials,
  .features {
    padding: 30px 10px;
  }

  .footer p {
    font-size: 0.8rem;
  }
}



.testimonials-section {
  text-align: center;
  padding: 2rem;
  color: white;
}

.testimonials-section .title {
  margin-top: 2%;
  font-size: 200%;
}

.testimonials-section .subtitle {
  margin-top: 2%;
  font-size: 120%;
  
}
.testimonials-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;

}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.testimonial {
  max-width: 300px;
  padding: 1rem;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  color: black;
  
}

.testimonial-text h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #013220; /* Ajuste de cor para combinar com seu estilo */
}

.testimonial-text h4 {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #555;
}

.arrow-left, .arrow-right {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  margin: 0 20px; /* Adiciona 20px de margem horizontal */
}

.arrow-left:disabled {
  opacity: 0.5; /* Torna o botão menos opaco */
  cursor: not-allowed; /* Muda o cursor para indicar que não é clicável */
}

.arrow-right:disabled {
  opacity: 0.5; /* Torna o botão menos opaco */
  cursor: not-allowed; /* Muda o cursor para indicar que não é clicável */
}
.arrow-left:hover, .arrow-right:hover {
  background-color: #fff;
  color: #013220;
  border-color: #013220;
}

.cta-button {
  margin-top: 2rem;
  padding: 0.75rem 2rem;
  background-color: #f4f1eb;
  
  color: #013220;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #028a0f;
}

@media (max-width: 768px) {
  .testimonials-grid {
    grid-template-columns: 1fr;
  }
}

.pricing-section {
  background-color: #f4f1eb;
  padding: 50px;
  text-align: center;
  border-radius: 50px;
}



.pricing-header h2 {
  margin-top: 1%;
  color: #222;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 5%;
}

.pricing-card {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.pricing-card h3 {
  font-size: 1.5rem;
  color: #1e2a4e;
  
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-top: 5%;
}

.pricing-card ul li {
  padding: 10px 0;
  font-size: 1rem;
  color: #555;
  margin-top: 5%;
}

.pricing-card button {
  background-color: #013220;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.pricing-card button:hover {
  background-color: #028a0f;
  transition: background-color 0.3s, color 0.3s;
}

.destaque {
  background-color: #013220;
  color: white;
}

.destaque h3 {
  
  color: white;
}

.destaque ul li {
  
  color: white;
}

.destaque button {
  background-color: white;
  color: #081d54;
}

.destaque button:hover{
  color: white;
}

button:disabled {
  background-color: lightgray; /* Cor de fundo quando desabilitado */
  cursor: not-allowed; /* Muda o cursor para indicar que está desabilitado */
}


/* Responsividade para telas médias e pequenas */
@media (max-width: 1024px) {
  .pricing-grid {
    grid-template-columns: repeat(2, 1fr); /* Duas colunas em tablets */
  }
}

@media (max-width: 768px) {
  .pricing-grid {
    grid-template-columns: 1fr; /* Uma coluna em telas menores, como celulares */
    gap: 30px; /* Ajusta o espaçamento para ficar mais confortável */
  }

  .pricing-card {
    padding: 20px; /* Diminui o padding em telas pequenas */
  }
}

@media (max-width: 480px) {
  .pricing-card h3 {
    font-size: 1.2rem; /* Diminui o tamanho da fonte em telas muito pequenas */
  }

  .pricing-card ul li {
    font-size: 0.9rem; /* Ajusta o tamanho do texto das listas */
  }

  .pricing-card button {
    font-size: 0.9rem; /* Ajusta o tamanho do botão */
    padding: 8px 16px; /* Reduz o padding do botão */
  }
}
