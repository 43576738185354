 /* body {
    font-family: Arial, sans-serif;
    background-color: #f4f6f9;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
  
  /* Estilos específicos para o componente Login */
    .login-container {
        background-color: white;
        width: 900px;
        display: flex;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        margin-top: 12%;
        margin-left: 30%;
    }
  
  .login-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .login-image-container img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  .login-form-container {
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f3f3f3;
  }
  
  .login-form-container h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #013220;
  }
  
  .login-form-container input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-form-container button {
    width: 100%;
    padding: 12px;
    background-color: #013220;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .login-form-container button:hover {
    background-color: #303f9f;
  }
  
  .login-form-container .social-login {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }
  
  .social-login button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .social-login button i {
    margin-right: 8px;
  }
  
  .social-login button:hover {
    background-color: #ddd;
  }
  
  .login-remember-me {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .login-remember-me label {
    margin-left: 5px;
  }
  
  .login-forgot-password {
    text-align: right;
    margin-bottom: 20px;
  }
  
  .login-forgot-password a {
    color: #3f51b5;
    text-decoration: none;
  }
  
  .login-forgot-password a:hover {
    text-decoration: underline;
  }
  
  .login-message {
    color: red;
    margin-top: 15px;
    text-align: center;
  }
  
  /* Responsividade */
  @media screen and (max-width: 768px) {
    .login-container {
      flex-direction: column;
      width: 100%;
      height: auto;
    }
  
    .login-form-container,
    .login-image-container {
      flex: none;
      width: 100%;
    }
  
    .login-form-container {
      padding: 20px;
    }
  
    .login-image-container {
      padding: 10px;
    }
  
    .login-image-container img {
      max-width: 80%;
      margin: 0 auto;
      display: block;
    }
  }
  
  @media screen and (max-width: 480px) {
    .login-form-container h2 {
      font-size: 24px;
    }
  
    .login-form-container input,
    .login-form-container button {
      font-size: 14px;
      padding: 8px;
    }
  
    .login-form-container {
      padding: 10px;
    }
  
    .social-login button {
      font-size: 14px;
      padding: 8px;
    }
  
    .login-container {
      box-shadow: none; /* Remove sombra em dispositivos menores */
    }
  
    .login-image-container img {
      max-width: 70%;
      margin: 0 auto;
      display: block;
    }
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    color: #013220;
  }
  
  .modal h3 {
    margin-bottom: 10px;
  }
  
  .modal p {
    margin-bottom: 20px;
  }
  
  .modal button {
    padding: 10px 20px;
    background: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal button:hover {
    background: #0056b3;
  }
  