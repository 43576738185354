/* Estilo principal do container */
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px; /* Aumenta a largura para encaixar mais detalhes */
  color: white;
  background-color: #1a1a1a; /* Cor de fundo escura, como no design da imagem */
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

/* Estilo para os detalhes do produto */
.details {
  width: 100%;
  background-color: transparent;
  color: white;
  padding: 20px;
  border-radius: 10px;
}

.details p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 1.6;
  color: #e0e0e0;
}

.details input {
  margin-top: 5%;
  color: white;
  background-color: #2e2e2e;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
}

.details h1 {
  font-size: 24px;
  color: white;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Estilo para a imagem do produto */
.productImage {
  max-width: 150px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover;
}

/* Estilo para os campos de entrada */
.inputField {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}

.inputField label {
  font-size: 14px;
  color: #e0e0e0;
  
}

.inputField input {
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 2%;
}

/* Abas de navegação */
.tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.tabs a {
  color: #aaa;
  text-decoration: none;
  padding: 10px 15px;
  border-bottom: 3px solid transparent;
  transition: color 0.3s, border-bottom 0.3s;
}

.tabs a.active {
  color: white;
  border-bottom: 3px solid #fff;
}

.tabs a:hover {
  color: #fff;
}

/* Outros estilos gerais */
.details .highlight {
  color: #f0ad4e;
  font-weight: bold;
}

.details .price {
  font-size: 22px;
  color: #d9534f;
}

.chartContainer {
  width: 80%; /* ou outro valor desejado */
  height: 500px; /* ajuste para o tamanho desejado */
  margin: 0 auto; /* centraliza o gráfico */
}
