/* CSS exclusivo para OpportunitiesPage */

.opportunities-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #121212;
    color: #ffffff;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 2rem;
    color: #ffffff;
  }
  
  .header p {
    font-size: 1rem;
    color: #aaaaaa;
  }
  
  .loading,
  .error {
    text-align: center;
    font-size: 1.2rem;
    color: #ff4c4c;
  }
  
  .products-table {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .product-card {
    display: flex;
    flex-direction: column;
    background-color: #1d1d1d;
    border-radius: 8px;
    width: 300px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: scale(1.05);
  }
  
  .product-info {
    padding: 15px;
  }
  
  .product-info h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .product-actions {
    display: flex;
    justify-content: center;
  }
  
  .product-actions a {
    background-color: #2e2e2e;
    color: #ffffff;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .product-actions a:hover {
    background-color: #555555;
  }
  
  @media (max-width: 768px) {
    .product-card {
      width: 100%;
    }
  
    .products-table {
      gap: 10px;
    }
  }
  